
  import { computed, defineComponent, watch, reactive, ref } from 'vue';
  import { useMutation, useQuery, useResult } from '@vue/apollo-composable';

  import gql from 'graphql-tag';
  import { DateTime } from 'luxon';
import { useRoute, useRouter } from 'vue-router';
import { useSecurityStore } from '@/modules/security/store';
import Constants from '@/constants';

  const prefixes = [
    'af',
    'aan',
    'bij',
    'de',
    'den',
    'der',
    'd\'',
    'het',
    '\'t',
    'in',
    'onder',
    'op',
    'over',
    '\'s',
    '\'t',
    'te',
    'ten',
    'ter',
    'tot',
    'uit',
    'uijt',
    'van',
    'ver',
    'voor',
  ];

  export default defineComponent({
    setup() {
      const router = useRouter();
      const route = useRoute();

      const activeTab = ref('data');

      const flags = reactive({
        setPicture: false,
        isParentSearching: false,
        parentSearch: {
          father: false,
          mother: false,
        },
      });

      const { result, loading, refetch } = useQuery(gql`
        query {
          persons {
            id
            picture
            father {
              id
              firstNames
              nickname
              lastName
              maidenName
              picture
            }
            mother {
              id
              firstNames
              nickname
              lastName
              maidenName
              picture
            }
            gender
            firstNames
            nickname
            lastName
            maidenName
            bornAt
            bornIn
            diedAt
            diedIn
            remarks
            photos {
              id
              files(reference: "front") {
                id
                width
                height
                annotations {
                  id
                  data {
                    ... on PersonAnnotation {
                      person {
                        id
                      }
                      radius
                      x
                      y
                    }
                  }
                }
              }
            }
          }
        }
      `, null, {
        fetchPolicy: 'no-cache',
      });

      const createdPersons = ref<any[]>([]);

      const persons = computed(() => {
        const data = [
          ...createdPersons.value.filter((person: any) => {

            return (result.value ? result.value.persons : []).find((x: any) => {
              return x.id === person.id;
            }) !== -1;
          }),
          ...(result.value ? result.value.persons : []),
        ];

        const withoutLastname: any[] = [];
        const lastNames: string[] = [];
        const persons: { [lastName: string]: any[] } = {};

        data.forEach((person) => {
          if (!person.lastName) {
            withoutLastname.push(person);

            return;
          }

          if (lastNames.indexOf(person.lastName) === -1) {
            lastNames.push(person.lastName);
          }

          if (!persons[person.lastName]) {
            persons[person.lastName] = [];
          }

          persons[person.lastName].push(person);
        });

        if (activePersonId.value && activePersonId.value !== fields.id) {
          const person = data.find((person: any) => person.id === activePersonId.value);

          setActivePerson(person);
        }

        // Sort the last names.
        lastNames.sort((a, b) => {
          const aParts = a.toLowerCase().split(' ');
          const bParts = b.toLowerCase().split(' ');

          const aPartsSanitized: string[] = [];
          const bPartsSanitized: string[] = [];

          // Remove the prefixes.
          aParts.forEach((part) => {
            if (prefixes.indexOf(part) === -1) {
              aPartsSanitized.push(part);
            }
          });

          bParts.forEach((part) => {
            if (prefixes.indexOf(part) === -1) {
              bPartsSanitized.push(part);
            }
          });

          const aSanitized = aPartsSanitized.join(' ');
          const bSanitized = bPartsSanitized.join(' ');

          if (aSanitized < bSanitized) {
            return -1;
          }

          if (aSanitized > bSanitized) {
            return 1;
          }

          return 0;
        });

        const sorted = lastNames.map((lastName) => {
          return {
            lastName,
            persons: persons[lastName].sort((a, b) => {
              const aName = (a.nickname || a.firstNames).toLowerCase();
              const bName = (b.nickname || b.firstNames).toLowerCase();

              if (aName < bName) {
                return -1;
              }

              if (aName > bName) {
                return 1;
              }

              return 0;
            }),
          };
        });

        sorted.push({
          lastName: ' ',
          persons: withoutLastname,
        });

        return sorted;
      });

      const activePersonFather = ref<any | undefined>(undefined);
      const activePersonMother = ref<any | undefined>(undefined);

      const getPersonName = (personId: string): string => {
        const data = [
          ...createdPersons.value,
          ...(result.value.persons ? result.value.persons : []),
        ];

        const person = data.find((person: any) => person.id === personId);

        return `${person.nickname || person.firstNames} ${person.lastName}${person.maidenName && person.maidenName.length > 0 ? `-${person.maidenName}` : ''}`;
      };

      const fields = reactive({
        fatherId: '',
        motherId: '',
        maidenName: '',
        firstNames: '',
        lastName: '',
        nickname: '',
        gender: '',
        bornAt: '',
        bornIn: '',
        diedAt: '',
        diedIn: '',
        remarks: '',
        id: '',
      });

      const onParentFound = (person: any): void => {
        createdPersons.value.push(person);

        if (flags.parentSearch.father) {
          activePersonFather.value = person;

          fields.fatherId = person.id;
        } else if (flags.parentSearch.mother) {
          activePersonMother.value = person;

          fields.motherId = person.id;
        }

        flags.isParentSearching = false;
        flags.parentSearch.father = false;
        flags.parentSearch.mother = false;
      };

      const activePersonId = ref<string | undefined>(route.params.personId ? (route.params.personId as string) : undefined);

      watch(activePersonId, (value) => {
        const person = result.value.persons.find((person: any) => person.id === value);

        window.scrollTo({
          top: 0,
        })

        activeTab.value = 'data';

        activePersonFather.value = undefined;
        activePersonMother.value = undefined;

        createdPersons.value = [];

        refetch();

        router.replace({
          params: {
            personId: person.id,
          },
          name: 'persons.single',
        });
      });

      const setActivePerson = (person: any) => {
        fields.fatherId = person.father ? person.father.id : '';
        fields.motherId = person.mother ? person.mother.id : '';
        fields.maidenName = person.maidenName;
        fields.firstNames = person.firstNames;
        fields.lastName = person.lastName;
        fields.nickname = person.nickname;
        fields.gender = person.gender;
        fields.bornAt = person.bornAt && person.bornAt.length > 0 ? DateTime.fromISO(person.bornAt).setLocale('nl').toFormat('dd-MM-yyyy') : '';
        fields.bornIn = person.bornIn;
        fields.diedAt = person.diedAt && person.diedAt.length > 0 ? DateTime.fromISO(person.diedAt).setLocale('nl').toFormat('dd-MM-yyyy') : '';
        fields.diedIn = person.diedIn;
        fields.remarks = person.remarks;
        fields.id = person.id;

        if (person.father) {
          activePersonFather.value = person.father;
        }

        if (person.mother) {
          activePersonMother.value = person.mother;
        }
      };

      const activePerson = computed(() => {
        if (!activePersonId.value || !result.value) {
          return;
        }

        return result.value.persons.find((person: any) => {
          return person.id === activePersonId.value;
        });
      });

      const showDate = (date: string): string => {
        return DateTime.fromISO(date).setLocale('nl').toLocaleString(DateTime.DATE_FULL);
      };

      const getBirthYear = (date: string): string => {
        return DateTime.fromISO(date).setLocale('nl').toFormat('yyyy');
      };

      const { mutate: updatePerson, onDone: onUpdatePersonDone } = useMutation(gql`
        mutation UpdatePerson(
          $id: ID!
          $fatherId: ID
          $motherId: ID
          $firstNames: String
          $nickname: String
          $lastName: String
          $maidenName: String
          $gender: Gender
          $bornAt: DateTime
          $bornIn: String
          $diedAt: DateTime
          $diedIn: String
          $remarks: String
        ) {
          updatePerson(
            id: $id
            input: {
              father: $fatherId
              mother: $motherId
              firstNames: $firstNames
              nickname: $nickname
              lastName: $lastName
              maidenName: $maidenName
              gender: $gender
              bornAt: $bornAt
              bornIn: $bornIn
              diedAt: $diedAt
              diedIn: $diedIn
              remarks: $remarks
            }
          ) {
            person {
              id
            }
          }
        }
      `);

      onUpdatePersonDone(() => {
        createdPersons.value = [];

        refetch();

        personSaved.value = true;

        window.setTimeout(() => {
          personSaved.value = false;
        }, 1000);
      });

      const formatDate = (date: string): string => {
        if (date.length === 0) {
          return '';
        }

        const parts = date.split('-');

        return `${parts[2]}-${parts[1]}-${parts[0]}T00:00:00+00:00`;
      };

      const onSubmit = (): void => {
        updatePerson({
          id: activePerson.value.id,
          firstNames: fields.firstNames,
          nickname: fields.nickname,
          lastName: fields.lastName,
          maidenName: fields.gender === 'MALE' ? '' : fields.maidenName,
          gender: fields.gender,
          bornAt: formatDate(fields.bornAt),
          bornIn: fields.bornIn,
          diedAt: formatDate(fields.diedAt),
          diedIn: fields.diedIn,
          remarks: fields.remarks,
          fatherId: fields.fatherId,
          motherId: fields.motherId,
        });
      };

      const securityStore = useSecurityStore();

      const getUrlForFile = (file: any): string => {
        return `${Constants.CDN_URL}/${file.id}/thumbnail/${securityStore.authenticationToken}`;
      };

      const getPictureForPerson = (person: any): string => {
        return `${Constants.CDN_URL}/person/${person.id}/${securityStore.authenticationToken}?${person.picture}`;
      };

      const getRatioForFile = (file: any): number => {
        return (file.height / file.width) * 100;
      };

      const onPictureSet = (): void => {
        createdPersons.value = [];

        refetch();

        flags.setPicture = false;
      };

      const personSaved = ref(false);

      return {
        activePersonId,
        activePerson,
        persons,
        getBirthYear,
        showDate,
        loading,
        fields,

        onSubmit,
        onParentFound,
        personSaved,

        getPersonName,
        flags,

        getUrlForFile,
        getRatioForFile,
        getPictureForPerson,

        onPictureSet,

        activePersonFather,
        activePersonMother,

        activeTab,
      };
    },
  });
